<template>
  <drag class="drag" :data="draggableData" :disabled="currentTab === 'tasks'">
    <template v-slot:drag-image v-if="allowDrag && isChecked">
      <div v-if="isChecked" class="content-card">
        <div class="content-card-body" style="background-color: #EEF1FC;">
          <div class="content-icon">
            <img :src="getCardIcon" alt="icon-content" />
          </div>
        </div>
        <div class="content-name">
          {{
            __(":totalItemsSelected items selected", {
              totalItemsSelected: totalItemsSelected
            })
          }}
        </div>
      </div>
    </template>
    <div
      class="content-card"
      :class="cardSelectedClass"
      @click.stop="$emit('select')"
      @dblclick.stop="$emit(doubleClickAction)"
    >
      <div class="content-card-body">
        <div class="content-actions">
          <div>
            <el-checkbox
              ref="cardMultiSelect"
              class="card-select-checkbox"
              v-if="allowMultiSelect"
              v-model="isChecked"
              @change="$emit('checked-content', $event)"
            ></el-checkbox>
          </div>

          <div v-if="showDelete" class="display-on-card-hover">
            <el-tooltip :content="deleteTooltip">
              <i
                @click="$emit('delete')"
                class="el-icon-close cancel-icon cell-item-pointer"
              ></i>
            </el-tooltip>
          </div>
        </div>

        <div class="content-icon">
          <img :src="getCardIcon" alt="icon-content" />
        </div>

        <div class="content-actions-bottom">
          <div>
            <div
              class="view-data-button"
              v-if="showViewData"
              @click="$emit('view')"
            >
              {{ __("View Data") }}
            </div>
          </div>
        </div>
      </div>
      <div class="content-name">
        {{ cardText }}
      </div>
    </div>
  </drag>
</template>

<script>
import { EventBus } from "@/EventBus";
import { Drag } from "vue-easy-dnd";
import { mapState } from "vuex";

export default {
  name: "ContentCard",
  components: {
    Drag
  },
  data() {
    return {
      isChecked: false,
      dragDisabled: true
    };
  },
  props: {
    cardClass: {
      type: Object,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: "icon-microphone.svg"
    },
    cardText: {
      type: String,
      required: true
    },
    doubleClickAction: {
      type: String,
      required: false,
      default: "edit"
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showViewData: {
      type: Boolean,
      required: false,
      default: false
    },
    deleteTooltip: {
      type: String,
      required: false,
      default: __("Delete")
    },
    allowMultiSelect: {
      default: false,
      type: Boolean
    },
    draggableData: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowDrag: {
      default: true,
      type: Boolean
    },
    totalItemsSelected: {
      default: 0
    }
  },
  computed: {
    ...mapState("folders", {
      currentTab: state => state.currentTab
    }),

    getCardIcon() {
      let icon = "";
      try {
        icon = require("@/assets/icons/content-" + this.icon);
      } catch (e) {
        icon = require("@/assets/icons/" + this.icon);
      }
      return icon;
    },

    cardSelectedClass() {
      return (this.cardClass && this.cardClass.selected === true) ||
        this.isChecked === true
        ? "selected"
        : "";
    }
  },
  methods: {
    clearCheckedItems() {
      this.isChecked = false;
    }
  },
  mounted() {
    EventBus.$on("de-select-all", this.clearCheckedItems);
  },
  beforeDestroy() {
    EventBus.$off("de-select-all", this.clearCheckedItems);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;

.content-card {
  margin-right: 44px;
  margin-bottom: 20px;

  .content-card-body {
    width: 120px;
    height: 120px;
    padding: 10px;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 5px;

    .content-actions {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width: 120px;
      padding: 10px;
      justify-content: space-between;

      .display-on-card-hover {
        opacity: 0;

        i {
          font-size: 18px;
        }
      }

      /*checkbox*/
      ::v-deep .card-select-checkbox {
        opacity: 0;
        margin-bottom: 5px;

        .el-checkbox__input {
          .el-checkbox__inner {
            width: 18px;
            height: 18px;
            position: relative;
            margin-bottom: 5px;
            background-color: #c8cfdc;
            border-radius: 5px;
            border: none;

            &:after {
              display: none;
            }
          }

          &.is-checked {
            .el-checkbox__inner {
              background-color: var(--theme-color);

              &:after {
                display: block;
                content: "";
                position: absolute;
                left: 6px;
                top: 2px;
                width: 4px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
        }

        &.is-checked {
          opacity: 1;
        }
      }
    }

    .content-icon {
      display: flex;
      justify-content: center;

      img {
        width: 44px;
      }
    }
  }

  &:hover {
    .content-card-body {
      background-color: var(--theme-row-hover-color);
    }

    .content-actions {
      .display-on-card-hover {
        opacity: 1;
      }

      .card-select-checkbox {
        opacity: 1;
      }
    }

    .content-actions-bottom {
      .view-data-button {
        opacity: 1;
      }
    }
  }

  .content-name {
    width: 120px;
    padding: 0 10px;
    text-align: center;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.selected {
    .content-card-body {
      background-color: var(--theme-row-hover-color);
    }

    .card-selected-checkbox {
    }
  }

  .content-actions-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 120px;
    padding: 10px;
    justify-content: center;

    .view-data-button {
      opacity: 0;
      color: var(--theme-color);
      font-size: 13px;
      text-decoration: none;
      font-weight: bold;
      padding-bottom: 8px;
    }

    .view-data-button:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.drag {
  transition: all 0.5s;
}
</style>

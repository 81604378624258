<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Name") }}</div>
    <div class="details">{{ contentForm.tts_package_name }}</div>

    <div
      class="titles"
      v-if="
        contentForm.tts_package_voices && contentForm.tts_package_voices.length
      "
    >
      {{ __("Voices") }}
    </div>
    <el-scrollbar :native="false">
      <div style="max-height: 50vh;">
        <el-tag
          :key="'_tts_voice_id_' + tts_package_voice"
          v-for="tts_package_voice in contentForm.tts_package_voices"
          style="margin-right: 2px;"
        >
          {{ getTtsVoiceNameFromTree(tts_package_voice[1]) }}
        </el-tag>
      </div>
    </el-scrollbar>
    <el-button
      @click="handleEditBtnClick"
      class="editBtn"
      v-show="this.canWrite()"
      >{{ __("Edit TTS Package") }}</el-button
    >
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <el-scrollbar ::native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="6"></el-col>
              <el-col :span="18">
                <page-header :title="__('TTS Package')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="6"></el-col>
              <el-col :span="9">
                <el-form-item prop="tts_package_name" :label="__('Name')">
                  <el-input v-model="contentForm.tts_package_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="6"></el-col>
              <el-col :span="9">
                <el-form-item
                  prop="tts_package_voices"
                  :label="__('TTS Voices')"
                >
                  <el-cascader
                    v-model="contentForm.tts_package_voices"
                    style="width: 100%"
                    :key="cascaderKey"
                    :options="ttsVoices"
                    :props="voice_props"
                    clearable
                    filterable
                    :collapse-tags="shouldCollapse"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              v-show="this.canWrite()"
              :primaryKey="id"
              variant="CreateUpdate"
            />
            <el-button @click="handleCancel" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapState, mapActions, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { checkUniqueName } from "@/api/ttspackages";
export default {
  mixins: [BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  data() {
    let validateTTSName = async (rule, value, callback) => {
      try {
        // update scenario
        if (this.id === 0 && value !== this.contentFormInfo.tts_package_name) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Name already exists")));
          } else {
            callback();
          }
        }
        if (this.id === -1) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Name already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };
    return {
      rules: {
        tts_package_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validateTTSName, trigger: "blur" }
        ],
        tts_package_voices: [
          {
            required: true,
            trigger: "blur",
            message: __("voices are required")
          }
        ]
      },
      isSubmitting: false,
      voice_props: { multiple: true }
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("ttsVoices", {
      ttsVoices: state => state.ttsVoices,
      ttsVoicesIsLoading: state => state.isLoading
    }),

    ...mapGetters("ttsVoices", {
      getTtsVoiceNameFromTree: "getTtsVoiceNameFromTree"
    }),
    cascaderKey() {
      return this.contentFormInfo.id > 0
        ? this.contentFormInfo.id
        : Math.random();
    },
    shouldCollapse() {
      return this.contentForm.tts_package_voices.length > 50;
    }
  },
  methods: {
    ...mapActions("ttspackages", {
      createTtsPackage: "createTtsPackage",
      updateTtsPackage: "updateTtsPackage",
      cleanUpTtsPackages: "cleanUpTtsPackages"
    }),

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createTtsPackage : this.updateTtsPackage;
          this.contentForm.ac_id = this.selectedAccountId;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("TTS Package added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("TTS Package updated successfully")
                  });
              this.cleanUpTtsPackages();
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              // console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

.addVoice {
  ::v-deep .el-input__inner {
    background: white;
    text-align: center;
    color: var(--theme-color);
    border: var(--theme-color) 2px solid;
    &::placeholder {
      color: var(--theme-color);
      font-weight: bold;
    }
  }

  ::v-deep .el-input.is-focus {
    border-color: var(--theme-color);
    outline: none;
  }
  ::v-deep .el-input__suffix {
    display: none;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
<style lang="scss">
.editContentItemModal {
  padding: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>

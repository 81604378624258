<template>
  <div style="width: 100%;">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('TTS Packages')"
    />
    <el-button
      class="createBtn"
      v-show="canWrite()"
      @click.stop="createContentItem"
      >{{ __("Create TTS Package") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentCardsContainer">
          <el-row
            type="flex"
            justify="start"
            class="list-cards"
            v-loading="loading"
          >
            <el-col
              :xl="4"
              :lg="6"
              :md="6"
              :sm="6"
              :xs="8"
              v-for="(ttsPackage, index) in ttsPackages"
              :key="index"
            >
              <ContentCard
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-tts-packages.svg"
                :allow-multi-select="false"
                :card-text="ttsPackage.tts_package_name"
                @delete="handleDelete(ttsPackage)"
                @select="handleSelect(index, ttsPackage)"
                @edit="handleEdit(index, ttsPackage)"
                :show-delete="canWrite()"
              ></ContentCard>
            </el-col>
          </el-row>
          <el-row
            v-if="!ttsPackages || !ttsPackages.length"
            style="text-align: center;color: #A0A8B5;"
            >{{ __("No data") }}</el-row
          >
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
// import ContentCard from "@/components/ContentCard";
import ContentCard from "@/views/build/content/mixins/ContentCard";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "TTSPackagesList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentCard,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["tts_package_name", "tts_package_id"],
      isContent: false
    };
  },

  computed: {
    ...mapState("ttspackages", {
      ttsPackages: state => state.ttsPackages,
      loading: state => state.loading
    })
  },

  methods: {
    ...mapActions("ttspackages", {
      contentAPI: "getTtsPackages",
      deleteContentMethod: "deleteTtsPackage",
      undoDeleteContent: "undoDeleteTtsPackage"
    })
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>

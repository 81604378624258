<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState, mapActions } from "vuex";

export default {
  name: "TTSPackagesIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("ttspackages", {
      contents: state => state.ttsPackages
    })
  },

  methods: {
    ...mapActions("ttsVoices", {
      getTTSVoicesTree: "getTTSVoicesTree"
    }),

    initContent() {
      return {
        tts_package_name: "",
        tts_package_voices: []
      };
    }
  },

  mounted() {
    this.getTTSVoicesTree({ tree: 1 });
    this.contentType = "TTS Package";
    this.primaryKey = "tts_package_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
